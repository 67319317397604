import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
	ErrorScheme,
	Residence,
	ResidenceGroup,
	ResidenceGroupScheme,
	User,
} from "client-v2";
import { Constant } from "client-v2";
import {
	Input,
	Text,
	Button,
	Color,
	Checkbox,
	Feedback,
	ColorV2,
	Form,
	Filter,
} from "@adoptaunabuelo/react-components";
import AdminBar from "../../../components/Nav/AdminBar";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const Container = styled.div`
	position: relative;
	display: flex;
	width: -webkit-fill-available;
	height: 100vh;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const RegisterContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
`;
const RegisterView = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	background-color: white;
	padding: 24px 48px;
	border-radius: 12px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const SearchView = styled.div`
	position: relative;
	display: flex;
	flex: 1;
`;
const DropdownMenu = styled.div`
	position: absolute;
	z-index: 1000;
	width: 100%;
	background-color: white;
	overflow: hidden;
	border-radius: 12px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	top: 64px;
`;
const SuggestionView = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	padding: 12px 16px;
	cursor: pointer;
	background-color: white;
	&:hover {
		background-color: ${Color.background.soft};
	}
`;

const Register = (props: Props) => {
	const navigate = useNavigate();
	const [timer, setTimer] = useState<any>(undefined);
	const currentUser = useSelector((state: State) => state.currentUser);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState<string | undefined>(undefined);
	const [phone, setPhone] = useState<{
		value: string | undefined;
		countryCode: string;
	}>({
		value: undefined,
		countryCode: "+34",
	});
	const [error, setError] = useState<string | undefined>(undefined);
	const [form, setForm] = useState<any>({
		address: "",
		shortAddress: "",
		route: "",
		routeNumber: "",
		routeInfo: "",
		city: "",
		province: "",
		zipCode: "",
		country: "",
		location: {
			lat: 0,
			lng: 0,
		},
		timeZone: "",
	});
	const [adminResidences, setAdminResidences] = useState<any[]>();
	const [adminId, setAdminId] = useState<string | undefined>(undefined);

	const [selectedPrograms, setSelectedPrograms] = useState<Array<string>>([]);
	const [groupOptions, setGroupOptions] = useState<ResidenceGroupScheme[]>(
		[]
	);
	const [residenceGroup, setResidenceGroup] = useState<any>(undefined);
	const [residenceFocus, setResidenceFocus] = useState(false);

	useEffect(() => {
		getAdminResidences();
	}, []);

	const onSaveClick = async () => {
		if (
			name &&
			phone.value &&
			phone.countryCode &&
			selectedPrograms.length > 0 &&
			residenceGroup &&
			form.address &&
			adminId
		) {
			setLoading(true);
			const temp = selectedPrograms.map((item: any) => item.id);
			const temp2 = {
				letters: temp.includes("letters"),
				offline: temp.includes("offline"),
				call: temp.includes("call"),
			};
			Residence.create({
				name: name,
				phone: phone.countryCode + phone.value,
				address: form.address,
				shortAddress: form.sortAddress,
				residenceGroupId: residenceGroup.objectId,
				adminId: adminId,
				timeZone: form.timeZone,
				city: form.city,
				country: form.country,
				zipCode: form.zipCode,
				province: form.province,
				route: form.route,
				routeNumber: form.routeNumber,
				routeInfo: form.routeInfo,
				location: {
					latitude: form.location.lat,
					longitude: form.location.lng,
				},
				type: temp2,
				fromId: currentUser.objectId,
			})
				.then((result) => {
					setLoading(false);
					navigate("/residencias/perfil/?id=" + result.data.objectId);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setError(error.message);
				});
		} else {
			if (!name) setError("Debe añadir el nombre de la residencia");
			else if (!phone.value)
				setError("Debes añadir un número de teléfono válido");
			else if (selectedPrograms.length === 0)
				setError(
					"Debes seleccionar al menos un programa para la residencia"
				);
			else if (form.address === "")
				setError(
					"Debes añadir una dirección aproximada para la residencia"
				);
			else if (!residenceGroup)
				setError(
					"Debes seleccionar un grupo residencial. Si la residencia no pertenece a un grupo, selecciona Independiente"
				);
		}
	};

	const onLocationChangeForm = (result: any) => {
		setForm(result.data);
	};

	const assignAdminToResidence = async (adminId: string) => {
		setAdminId(adminId);
	};

	const getAdminResidences = async () => {
		User.get({
			type: "admin",
			data: {
				department: ["support"],
			},
			active: true,
		}).then((result) => {
			const temp = result.data.map((item) => ({
				...item,
				id: item.objectId,
				label: item.name + " " + item.surname,
			}));
			setAdminResidences([...temp]);
		});
	};

	const onNameChange = (e: any) => {
		setError(undefined);
		setName(e.target.value);
	};

	const onPhoneChange = (e: any) => {
		setError(undefined);
		if (e.isValid)
			setPhone({
				value: e.value,
				countryCode: e.country,
			});
	};

	const onGroupChange = (e: any) => {
		if (timer) {
			clearTimeout(timer);
			setTimer(undefined);
		}
		setTimer(
			setTimeout(() => {
				const text = e.target.value;
				searchResidenceGroup(text);
			}, 500)
		);
	};

	const searchResidenceGroup = async (name: string) => {
		setLoading(true);
		setResidenceGroup(undefined);
		ResidenceGroup.get({
			name: name,
			limit: 5,
		})
			.then((result) => {
				setGroupOptions(result.data);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	const onResidenceGroupClick = (item: any) => {
		setResidenceGroup(item);
		setResidenceFocus(false);
		setGroupOptions([]);
	};

	const handleProgramChange = (items: Array<string>) => {
		setSelectedPrograms(items);
	};

	return (
		<Container>
			<Feedback
				isVisible={error ? true : false}
				type="error"
				text={error ? error : ""}
				onClose={() => setError(undefined)}
			/>
			<AdminBar
				style={{ marginBottom: 16 }}
				title="Registro de residencia"
				goBack={() => navigate(-1)}
			/>
			<RegisterContainer>
				<RegisterView>
					<Text
						type="h3"
						weight="semibold"
						style={{ marginTop: 12, marginBottom: 24 }}
					>
						Datos personales
					</Text>
					<Row style={{ gap: 12, marginBottom: 12 }}>
						<Input
							type="text"
							design={"secondary"}
							containerStyle={{ flex: 1 }}
							placeholder="Nombre"
							onChange={onNameChange}
						/>
						<Input
							design={"secondary"}
							containerStyle={{ flex: 1 }}
							placeholder="Teléfono"
							type="tel"
							countryOptions={Constant.countries()}
							country={phone.countryCode}
							onPhoneChange={onPhoneChange}
						/>
					</Row>
					<Col style={{ marginBottom: 12 }}>
						<Form
							type="location"
							design="secondary"
							googleAPIKey="AIzaSyDt-MpIS9a8SsQ-e1v6I6KrmnOLK4SA-f8"
							onSubmit={onLocationChangeForm}
						/>
					</Col>
					<Row style={{ marginBottom: 24 }}>
						<SearchView>
							<Input
								type="text"
								placeholder="Grupo residencial"
								design={"secondary"}
								value={
									residenceGroup
										? residenceGroup.name
										: undefined
								}
								containerStyle={{ flex: 1 }}
								onChange={onGroupChange}
								onFocus={() => setResidenceFocus(true)}
							/>
							{residenceFocus && (
								<DropdownMenu>
									{groupOptions.map((item: any) => (
										<SuggestionView
											key={item.id}
											onClick={() =>
												onResidenceGroupClick(item)
											}
										>
											<Text
												type="p2"
												style={{
													textOverflow: "ellipsis",
												}}
											>
												{item.name}
											</Text>
										</SuggestionView>
									))}
								</DropdownMenu>
							)}
						</SearchView>
					</Row>
					<Col>
						<Text
							type="p2"
							style={{ marginBottom: 12, color: Color.text.high }}
						>
							Programas
						</Text>
						<Checkbox
							type="multiple"
							options={[
								{
									id: "offline",
									label: "Visitas",
								},
								{
									id: "call",
									label: "Llamadas",
								},
							]}
							onChange={(items: any) =>
								handleProgramChange(items)
							}
						/>
					</Col>
					<Col>
						<Text
							type="p2"
							style={{ marginBottom: 12, color: Color.text.high }}
						>
							Asignar admin a la residencia
						</Text>
						<Filter
							id="adminResidence"
							placeholder="Asignar admin"
							type="single"
							shape="circle"
							style={{
								color: ColorV2.text.red,
							}}
							onChange={(selectedOption) =>
								assignAdminToResidence(selectedOption[0]?.id)
							}
							options={adminResidences || []}
							hideSearchBar={true}
						></Filter>
					</Col>
					<Row
						style={{
							flex: 1,
							justifyContent: "flex-end",
							alignItems: "flex-end",
						}}
					>
						<Button loading={loading} onClick={onSaveClick}>
							Guardar
						</Button>
					</Row>
				</RegisterView>
			</RegisterContainer>
		</Container>
	);
};
export default Register;
export interface Props {}
