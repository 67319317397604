import React, { useState, useEffect } from "react";
import {
	Residence,
	ResidenceGroup,
	ResidenceScheme,
	ResidenceGroupScheme,
	ErrorScheme,
} from "client-v2";
import { Constant } from "client-v2";
import { Input, Modal, Dropdown, Form } from "@adoptaunabuelo/react-components";

const ResidenceInfoModal = (props: Props) => {
	const phoneUtil =
		require("google-libphonenumber").PhoneNumberUtil.getInstance();

	const [residenceGroupOptions, setResidenceGroupOptions] = useState<
		{ id: string; title: string }[]
	>([]);
	const [timer, setTimer] = useState<any>(undefined);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState<string | undefined>(undefined);

	const [form, setForm] = useState<any>({
		address: props.residence.address ?? "",
		sortAddress: props.residence.shortAddress ?? "",
		city: props.residence.city ?? "",
		zipCode: props.residence.zipCode ?? "",
		country: props.residence.country ?? "",
		province: props.residence.province ?? "",
		route: props.residence.route ?? "",
		routeNumber: props.residence.routeNumber ?? "",
		routeInfo: props.residence.routeInfo ?? "",
		location: {
			lat: props.residence.location.latitude ?? 0,
			lng: props.residence.location.longitude ?? 0,
		},
		timeZone: props.residence.timeZone ?? "Europe/Madrid",
	});

	const [phone, setPhone] = useState<{
		value: string | undefined;
		countryCode: string | undefined;
	}>({
		value: undefined,
		countryCode: undefined,
	});
	const [residenceGroupId, setResidenceGroupId] = useState<
		{ id: string; title: string } | undefined
	>(
		props.residenceGroup
			? {
					id: props.residenceGroup.objectId,
					title: props.residenceGroup.name,
			  }
			: undefined
	);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		if (props.residence.phone) {
			const result = phoneUtil.parseAndKeepRawInput(
				props.residence.phone
			);
			const values = result.values_;
			setPhone({
				value: values[2].toString(),
				countryCode: "+" + values[1],
			});
		} else {
			setPhone({
				value: undefined,
				countryCode: "+34",
			});
		}
	}, []);

	const onSave = async () => {
		setLoading(true);
		Residence.set(props.residence.objectId, {
			name: name,
			address: form.data.address,
			shortAddress: form.data.shortAddress,
			location: {
				latitude: form.data.location.lat,
				longitude: form.data.location.lng,
			},
			province: form.data.province,
			route: form.data.route,
			routeNumber: form.data.routeNumber,
			routeInfo: form.data.routeInfo,
			country: form.data.country,
			city: form.data.city,
			zipCode: form.data.zipCode,
			timeZone: form.data.timeZone,
			phone:
				phone.countryCode && phone.value
					? phone.countryCode + phone.value
					: undefined,
			residenceGroupId: residenceGroupId?.id,
		})
			.then((residence) => {
				setLoading(false);
				props.onSave(residence.data);
			})
			.catch((error: ErrorScheme) => {
				setError(error.message);
				setLoading(false);
			});
	};

	const onLocationChangeForm = (result: any) => {
		setForm(result);
	};

	const onNameChange = (e: any) => {
		setName(e.target.value);
	};

	const onPhoneChange = (e: any) => {
		if (e.isValid) {
			setPhone({
				countryCode: e.country,
				value: e.value,
			});
		}
	};

	const onSearch = (text: string) => {
		if (timer) {
			clearTimeout(timer);
			setTimer(undefined);
		}
		setTimer(
			setTimeout(() => {
				searchResidenceGroup(text);
			}, 500)
		);
	};

	const searchResidenceGroup = async (text: string) => {
		setLoading(true);
		ResidenceGroup.get({
			name: text,
			limit: 5,
		})
			.then((result) => {
				const options = result.data.map((item) => {
					return {
						id: item.objectId,
						title: item.name,
					};
				});
				setResidenceGroupOptions(options);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	return (
		<Modal
			type="form"
			isVisible={props.isVisible}
			contentStyle={{ height: "inherit" }}
			title={"Información"}
			onClose={props.onClose}
			buttonProps={{
				onClick: onSave,
				loading: loading,
			}}
			error={error}
			options={[
				{
					id: "name",
					title: "Nombre",
					Data: (
						<Input
							type="text"
							placeholder="Nombre"
							defaultValue={props.residence.name}
							onChange={onNameChange}
						/>
					),
				},
				{
					id: "address",
					title: "Dirección",
					Data: (
						<Form
							type="location"
							design="secondary"
							googleAPIKey="AIzaSyDt-MpIS9a8SsQ-e1v6I6KrmnOLK4SA-f8"
							onSubmit={onLocationChangeForm}
						/>
					),
				},
				{
					id: "phone",
					title: "Teléfono",
					Data: (
						<Input
							type="tel"
							placeholder="Teléfono"
							defaultValue={phone.value}
							country={phone.countryCode}
							countryOptions={Constant.countries()}
							onPhoneChange={onPhoneChange}
						/>
					),
				},
				{
					id: "residenceGroup",
					title: "Grupo",
					Data: (
						<Dropdown
							id={"residence-group-dropdown"}
							placeholder={"Grupo residencial"}
							type="single"
							options={residenceGroupOptions}
							selectedOptions={
								residenceGroupId
									? [residenceGroupId]
									: undefined
							}
							onSearchChange={(text) => onSearch(text)}
							onChange={(array: Array<any>) =>
								setResidenceGroupId(array[0])
							}
						/>
					),
				},
			]}
		/>
	);
};
export default ResidenceInfoModal;
export interface Props {
	isVisible: boolean;
	residence: ResidenceScheme;
	residenceGroup?: ResidenceGroupScheme;
	onClose: () => void;
	onSave: (residence: ResidenceScheme) => void;
}
