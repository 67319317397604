import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
	ResidenceScheme,
	Dash,
	UserScheme,
	ErrorScheme,
	ActivityRenderScheme,
	Activity,
	User,
	ActivityScheme,
	Residence,
} from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import Row from "../../../components/Containers/Row";
import Col from "../../../components/Containers/Col";
import AdminBar from "../../../components/Nav/AdminBar";
import SectionMain from "./SectionMain";
import SectionContact from "./SectionContact";
import SectionReporting from "./SectionReporting";
import SectionGrandpa from "./SectionGrandpa";
import SectionVolunteer from "./SectionVolunteer";
import SectionNotes from "./SectionNotes";
import SectionHistory from "./SectionHistory";
import { useLocation } from "react-router-dom";

import { Button, ColorV2, Filter } from "@adoptaunabuelo/react-components";
import { Phone, Eye, RockingChair } from "lucide-react";

const Container = styled.div`
	display: flex;
	width: -webkit-fill-available;
	flex-direction: column;
	padding: 0px 32px 32px;
`;

const Profile = (props: Props) => {
	const dispatch = useDispatch();

	const location = useLocation();
	const { setCallUser, setResidenceProfileFunction, setLoading, setError } =
		bindActionCreators(actionsCreators, dispatch);
	const navigate = useNavigate();
	const callUser = useSelector((state: State) =>
		state.call ? state.call.user : undefined
	);
	const residenceProfileFunction = useSelector(
		(state: State) => state.profile
	);

	const [residence, setResidence] = useState<ResidenceScheme | undefined>(
		undefined
	);
	const [calls, setCalls] = useState<ActivityRenderScheme[]>([]);
	const [notes, setNotes] = useState<ActivityRenderScheme[]>([]);
	const [grandpas, setGrandpas] = useState<UserScheme[]>([]);
	const [nGrandpas, setNGrandpas] = useState(0);
	const [volunteers, setVolunteers] = useState<UserScheme[]>([]);
	const [nVolunteers, setNVolunteers] = useState(0);
	const [adminResidences, setadminResidences] = useState<any[]>(
		location.state?.adminResidences || []
	);

	const currentUser = useSelector((state: State) => state.currentUser);

	useEffect(() => {
		setResidenceProfileFunction({ launch: true, function: "all" });
		if (adminResidences.length === 0) {
			getAdminResidences();
		}
	}, []);

	useEffect(() => {
		const residenceId = new URLSearchParams(window.location.search).get(
			"id"
		);
		if (residenceProfileFunction.launch && residenceId) {
			get(residenceId);
		}
	}, [residenceProfileFunction]);

	const getAdminResidences = async () => {
		User.get({
			type: "admin",
			data: {
				department: ["support", "sales", "admin"],
			},
			active: true,
		}).then((result) => {
			const temp = result.data.map((item) => ({
				...item,
				id: item.objectId,
				label: item.name + " " + item.surname,
			}));
			setadminResidences([...temp]);
		});
	};

	const get = (residenceId: string) => {
		if (residenceProfileFunction.function === "all") {
			setLoading(true);
			Dash.getResidence(residenceId)
				.then((result) => {
					setResidence(result.data.Residence);
					setNotes(result.data.Notes);
					setCalls(result.data.Calls);
					setGrandpas(result.data.Grandpas.data);
					setNGrandpas(result.data.Grandpas.pagination.count);
					setVolunteers(result.data.Volunteers.data);
					setNVolunteers(result.data.Volunteers.pagination.count);
					setLoading(false);
				})
				.catch((e: ErrorScheme) => {
					setLoading(false);
					setError(true, e.message);
				});
		} else if (residence) {
			if (residenceProfileFunction.function === "notes") {
				getNotes(residence)
					.then(async (result) => {
						const notes = generateScheme(result.data, "note");
						setNotes(notes);
					})
					.catch((error: ErrorScheme) => {
						setError(true, error.message);
					});
			} else if (residenceProfileFunction.function === "calls") {
				getCalls(residence)
					.then((result) => {
						const calls = generateScheme(result.data, "call");
						setCalls(calls);
					})
					.catch((error: ErrorScheme) => {
						setError(true, error.message);
					});
			} else if (residenceProfileFunction.function === "grandpas") {
				getGrandpas(
					residence,
					residenceProfileFunction.data.states,
					residenceProfileFunction.data.services
				)
					.then((result) => {
						setGrandpas(result.data);
						setNGrandpas(result.pagination.count);
					})
					.catch((error: ErrorScheme) => {
						setError(true, error.message);
					});
			}
		}
		setResidenceProfileFunction({ launch: false, function: "all" });
	};

	const onResidenceChange = (residence: ResidenceScheme) => {
		setResidence(residence);
	};

	const getNotes = async (residence: ResidenceScheme) => {
		return Activity.get({
			type: "note",
			data: {
				residenceId: residence.objectId,
			},
			descending: "createdAt",
			include: ["Admin"],
		});
	};

	const getCalls = async (residence: ResidenceScheme) => {
		return Activity.get({
			type: "call",
			data: {
				residenceId: residence.objectId,
			},
			descending: "createdAt",
			include: ["Admin"],
		});
	};

	const getGrandpas = async (
		residence: ResidenceScheme,
		states?: string[],
		services?: {
			letter: boolean;
			visit: boolean;
		}
	) => {
		let data = {};
		if (services) {
			if (services.letter) data = { ...data, letter: true };
			if (services.visit) data = { ...data, visit: true };
		}
		return User.get({
			type: "grandpa",
			residenceId: residence.objectId,
			state:
				states && states.length > 0
					? states
					: [
							"registered",
							"match",
							"paused",
							"inRegister",
							"waiting",
					  ],
			data: data,
		});
	};

	const generateScheme = (
		items: ActivityScheme[],
		type: string
	): ActivityRenderScheme[] => {
		return items.map((item) => {
			if (type === "call") {
				return {
					objectId: item.objectId,
					type: item.type,
					From: item.From,
					timestamp: moment(item.createdAt).toDate(),
					title: "Llamada realizada",
					subtitle: `Realizó una llamada de ${item.data?.duration} min`,
					duration: item.data?.duration,
					start: item.data?.start,
					Admin: item.Admin
						? {
								name: item.Admin.name,
								image: item.Admin.image?.url,
						  }
						: undefined,
				};
			} else {
				return {
					objectId: item.objectId,
					type: item.type,
					From: item.From,
					timestamp: moment(item.createdAt).toDate(),
					title: "Nota",
					subtitle: item.data && item.data.note ? item.data.note : "",
					pinned: item.data?.pinned,
					Admin: item.Admin
						? {
								name: item.Admin.name,
								image: item.Admin.image?.url,
						  }
						: undefined,
				};
			}
		});
	};

	const assignAdminToResidence = (adminId: string) => {
		setLoading(true);
		if (residence) {
			Residence.set(residence.objectId, {
				fromId: currentUser.objectId,
				adminId: adminId,
			})
				.then((residence) => {
					setLoading(false);
					onResidenceChange(residence.data);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setError(true, error.message);
				});
		}
	};

	return (
		<Container>
			{residence && (
				<>
					<AdminBar
						style={{ marginBottom: 16 }}
						title="Detalle"
						goBack={() => navigate(-1)}
						RightBar={
							<>
								{currentUser.data.department === "admin" && (
									<Filter
										id="adminResidence"
										placeholder="Asignar admin"
										type="single"
										shape="circle"
										style={{
											color: ColorV2.text.red,
										}}
										onChange={(selectedOption: any) =>
											assignAdminToResidence(
												selectedOption[0]?.id
											)
										}
										options={adminResidences}
										selectedOptions={
											residence.Admin?.objectId
												? [
														{
															id: residence.Admin
																.objectId,
														},
												  ]
												: []
										}
										hideSearchBar={true}
									></Filter>
								)}
								{residence.phone && (
									<Button
										icon={<Phone height={20} width={20} />}
										size={"small"}
										style={{ marginLeft: 8 }}
										disabled={callUser}
										onClick={() => {
											const user: any = {
												objectId:
													"res_" + residence.objectId,
												name: residence.name,
												surname: "",
												phone: residence.phone,
											};
											setCallUser(user);
										}}
									>
										Llamar
									</Button>
								)}

								<Button
									icon={
										<RockingChair height={20} width={20} />
									}
									size={"small"}
									style={{ marginLeft: 8 }}
									onClick={() =>
										navigate("/abuelos/registro", {
											state: { residence },
										})
									}
								>
									Registrar abuelo
								</Button>
								<Button
									icon={<Eye height={20} width={20} />}
									size={"small"}
									style={{ marginLeft: 8 }}
									onClick={() =>
										window.open(
											"https://adoptaunabuelo.org/residencia/?id=" +
												residence.objectId,
											"_blank"
										)
									}
								>
									Ver web registro
								</Button>
							</>
						}
					/>
					<Row style={{ width: "100%" }}>
						<Col style={{ width: "33%" }}>
							<SectionMain
								residence={residence}
								onResidenceChanged={onResidenceChange}
							/>
							<SectionContact
								residence={residence}
								onResidenceChanged={onResidenceChange}
							/>
							<SectionHistory
								residence={residence}
								calls={calls}
							/>
						</Col>
						<Col
							style={{
								width: "33%",
								marginLeft: 16,
								marginRight: 16,
							}}
						>
							<SectionReporting
								residence={residence}
								onResidenceChanged={onResidenceChange}
							/>
							<SectionVolunteer
								residence={residence}
								volunteers={volunteers}
								volunteerCount={nVolunteers}
								onResidenceChanged={onResidenceChange}
							/>
							<SectionGrandpa
								residence={residence}
								grandpas={grandpas}
								grandpaCount={nGrandpas}
								onResidenceChanged={onResidenceChange}
							/>
						</Col>
						<Col style={{ width: "33%" }}>
							<SectionNotes
								residence={residence}
								notes={notes}
								onResidenceChanged={onResidenceChange}
							/>
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
};
export default Profile;
export interface Props {}
