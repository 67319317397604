import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { User, Regex, Constant, UserScheme, ErrorScheme } from "client-v2";
import Geocode from "react-geocode";

import { Input, Modal, Dropdown, Form } from "@adoptaunabuelo/react-components";

const Icon = styled.img`
	height: 24px;
	width: 24px;
`;

const InfomationModal = (props: Props) => {
	const phoneUtil =
		require("google-libphonenumber").PhoneNumberUtil.getInstance();

	const [loading, setLoading] = useState(false);
	const [languages] = useState(Constant.languages());
	const [error, setError] = useState<string>("");
	const data = props.user.data;
	const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";
	const [selectedLanguages, setSelectedLanguages] = useState<Array<any>>(
		data && data.languages
			? data.languages.map((item: string) => ({ id: item }))
			: []
	);
	
	const [selectedEmail, setSelectedEmail] = useState<string | undefined>(
		props.user.email
	);

	const [form, setForm] = useState<any>({
		address: "",
		sortAddress: "",
		route: "",
		routeNumber: "",
		routeInfo: "",
		city: "",
		province: "",
		zipCode: "",
		country: "",
		location: {
			lat: 0,
			lng: 0,
		},
		timeZone: "",
	});

	const [selectedPhone, setSelectedPhone] = useState<{
		phone: string | undefined;
		countryCode: string | undefined;
	}>({
		phone: undefined,
		countryCode: undefined,
	});
	const [selectedGender, setSelectedGender] = useState<string | undefined>(
		props.user.gender
	);
	const [selectedIdCard, setSelectedIdCard] = useState<string | undefined>(
		props.user.idCard
	);
	const [selectedLOPD, setSelectedLOPD] = useState<string | undefined>(
		props.user.internalData?.lopdUrl
	);

	useEffect(() => {
		Geocode.setApiKey(googleAPIKey);
		Geocode.setLanguage("es");
	}, []);

	useEffect(() => {
		if (!props.user.phone) {
			setSelectedPhone({
				phone: undefined,
				countryCode: "",
			});
			return;
		}
		const result = phoneUtil.parseAndKeepRawInput(props.user.phone);
		const values = result.values_;
		setSelectedPhone({
			phone: values[2].toString(),
			countryCode: "+" + values[1],
		});
	}, []);

	const onLocationChangeForm = (result: any) => {
		setForm(result.data);
	};

	const onSave = async () => {
		setLoading(true);
		setError("");
		const phone =
			selectedPhone.countryCode && selectedPhone.phone
				? selectedPhone.countryCode + selectedPhone.phone
				: undefined;

		User.set(props.user.objectId, {
			phone: phone === props.user.phone ? undefined : phone,
			email: selectedEmail,
			gender: selectedGender,
			idCard: selectedIdCard,
			address: form.address,
			shortAddress: form.sortAddress,
			location: {
				lat: form.location.lat,
				lng: form.location.lng,
			},
			country: form.country,
			city: form.city,
			zipCode: form.zipCode,
			timeZone: form.timeZone,
			route: form.route,
			routeNumber: form.routeNumber,
			routeInfo: form.routeInfo,
			province: form.province,
			data: {
				languages: selectedLanguages.map((item) => item.id),
			},
			internalData: {
				lopdUrl: selectedLOPD,
			},
		})
			.then((user) => {
				props.onSave(user.data);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setError(error.message);
				setLoading(false);
			});
	};

	const onLanguageChange = (array: Array<any>) => {
		setSelectedLanguages(array);
	};

	const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (Regex.email(e.target.value)) {
			setSelectedEmail(e.target.value);
		}
	};

	const onPhoneChange = (e: any) => {
		if (e.isValid) {
			setSelectedPhone({
				countryCode: e.country,
				phone: e.value,
			});
		}
	};

	const onGenderChange = (option: Array<any>) => {
		setSelectedGender(option[0].id);
	};

	return (
		<Modal
			type="form"
			isVisible={props.isVisible}
			title={"Información"}
			error={error}
			options={[
				{
					id: "phone",
					title: "Teléfono",
					Data: (
						<Input
							type="tel"
							placeholder="Teléfono"
							defaultValue={selectedPhone.phone}
							country={selectedPhone.countryCode}
							countryOptions={Constant.countries()}
							onPhoneChange={onPhoneChange}
						/>
					),
				},
				{
					id: "email",
					title: "Email",
					Data: (
						<Input
							type="email"
							placeholder="Email"
							defaultValue={props.user.email}
							onChange={onEmailChange}
						/>
					),
				},
				{
					id: "location",
					title: "Dirección",
					Data: (
						<Form
							type="location"
							design="primary"
							googleAPIKey="AIzaSyDt-MpIS9a8SsQ-e1v6I6KrmnOLK4SA-f8"
							onSubmit={onLocationChangeForm}
						/>
					),
				},
				{
					id: "separator",
				},
				{
					id: "gender",
					title: "Género",
					Data: (
						<Dropdown
							id="gender-dropdown"
							style={{ flex: 1 }}
							placeholder="Género"
							type="single"
							menuPosition="top"
							options={[
								{
									id: "male",
									title: "Hombre",
									icon: (
										<Icon
											src={require("../../assets/img/Man.png")}
										/>
									),
								},
								{
									id: "female",
									title: "Mujer",
									icon: (
										<Icon
											src={require("../../assets/img/Woman.png")}
										/>
									),
								},
							]}
							selectedOptions={
								selectedGender
									? [{ id: selectedGender, title: "" }]
									: []
							}
							onChange={onGenderChange}
						/>
					),
				},
				{
					id: "languages",
					title: "Idiomas",
					Data: (
						<Dropdown
							id={"language-dropdown"}
							placeholder={"Idiomas"}
							type="multiple"
							onChange={onLanguageChange}
							options={languages}
							selectedOptions={selectedLanguages}
						/>
					),
				},
				{
					id: "separator",
				},
				{
					id: "idCard",
					title: "DNI o NIE",
					Data: (
						<Input
							type="text"
							placeholder="DNI o NIE"
							defaultValue={props.user.idCard}
							onChange={(e) => setSelectedIdCard(e.target.value)}
						/>
					),
				},
				{
					id: "lopdUrl",
					title: "LOPD",
					Data: (
						<Input
							type="text"
							placeholder="URL a documento de Drive"
							defaultValue={props.user.internalData?.lopdUrl}
							onChange={(e) => setSelectedLOPD(e.target.value)}
						/>
					),
				},
				{
					id: "separator",
				},
			]}
			onClose={() => {
				setError("");
				props.onClose();
			}}
			buttonProps={{
				onClick: onSave,
				loading: loading,
			}}
		/>
	);
};
export default InfomationModal;
export interface Props {
	isVisible: boolean;
	user: UserScheme;
	onClose: () => void;
	onSave: (user: UserScheme) => void;
}
