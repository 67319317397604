import React, { ChangeEvent, useState } from "react";
import moment from "moment";
import { Challenge, ChallengeScheme } from "client-v2";

import { Modal, Input } from "@adoptaunabuelo/react-components";

const DetailModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [selection, setSelection] = useState({
		date: moment(props.challenge.endDate.iso).format("YYYY/MM/DD"),
		hour: moment(props.challenge.endDate.iso).format("HH:mm"),
		video: props.challenge.video,
	});

	const [error, setError] = useState<string | undefined>(undefined);

	const onSave = () => {
		if (selection.date && selection.hour) {
			setLoading(true);
			const date = moment(
				selection.date + " " + selection.hour,
				"YYYY/MM/DD HH:mm"
			).toDate();
			Challenge.set(props.challenge.objectId, {
				endDate: date,
				video: selection.video,
			})
				.then((challenge) => {
					setLoading(false);
					props.onSave && props.onSave(challenge.data);
				})
				.catch((e: string) => {
					setLoading(false);
					setError(e);
				});
		} else {
			setError(
				"Debes añadir el día y la hora a la que finaliza el sueño."
			);
		}
	};

	const handleDateChange = (event: ChangeEvent<HTMLInputElement> | Date) => {
		let dateValue: string | Date;

		if (event instanceof Date) {
			dateValue = event;
		} else if ("target" in event && event.target.value) {
			dateValue = event.target.value;
		} else {
			console.warn(
				"Advertencia: No se pudo obtener el valor de la fecha."
			);
			return;
		}

		const momentDate = moment(dateValue, moment.ISO_8601, true).isValid()
			? moment(dateValue)
			: moment(dateValue, "YYYY-MM-DD");

		if (momentDate.isValid()) {
			setSelection({
				...selection,
				date: momentDate.format("YYYY-MM-DD"),
			});
		}
	};

	return (
		<Modal
			type="form"
			isVisible={props.isVisible}
			onClose={props.onClose}
			title={"Editar detalles"}
			error={error}
			buttonProps={{
				children: "Guardar",
				loading: loading,
				onClick: onSave,
			}}
			options={[
				{
					id: "date",
					title: "Fecha de fin",
					Data: (
						<Input
							type="date"
							containerStyle={{ display: "flex", flex: 1 }}
							style={{ flex: 1 }}
							placeholder="Fecha"
							defaultValue={moment(selection.date).format(
								"DD/MM/YYYY"
							)}
							onChange={(e: any) => handleDateChange(e)}
						/>
					),
				},
				{
					id: "hour",
					title: "Hora de fin",
					Data: (
						<Input
							type="time"
							containerStyle={{ display: "flex", flex: 1 }}
							style={{ flex: 1 }}
							placeholder="Hora"
							defaultValue={moment(selection.hour).format(
								"HH:mm"
							)}
							value={selection.hour}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) =>
								setSelection({
									...selection,
									hour: e.target.value,
								})
							}
						/>
					),
				},
				{
					id: "video",
					title: "Url del video",
					Data: (
						<Input
							type="text"
							containerStyle={{ display: "flex", flex: 1 }}
							style={{ flex: 1 }}
							placeholder="Url del video"
							defaultValue={props.challenge.video}
							onChange={(e) => {
								setSelection({
									...selection,
									video: e.target.value,
								});
							}}
						/>
					),
				},
			]}
		></Modal>
	);
};
export default DetailModal;
export interface Props {
	isVisible: boolean;
	challenge: ChallengeScheme;
	onSave?: (challenge: ChallengeScheme) => void;
	onClose: () => void;
}
