import React, { useEffect, useState } from "react";
import {
	User,
	Constant,
	Residence,
	Corporate,
	UserScheme,
	ErrorScheme,
} from "client-v2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import { bindActionCreators } from "redux";

import { List, MapPin } from "lucide-react";
import SearchTable from "../../../components/Table/SearchTable";

const Table = (props: Props) => {
	const dispatch = useDispatch();
	const { setVolunteerTableFilters } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const volunteerTableFilters = useSelector(
		(state: State) => state.volunteerTableFilters
	);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [volunteers, setVolunteers] = useState<Array<UserScheme>>([]);
	const [count, setCount] = useState(0);
	const [states, setStates] = useState<Array<any>>([]);
	const [hobbies, setHobbies] = useState<Array<any>>([]);
	const [provinces, setProvinces] = useState<Array<any>>([]);
	const [countries, setCountries] = useState<Array<any>>([]);
	const [languages, setLanguages] = useState<Array<any>>([]);
	const [coorporates, setCoorporates] = useState<Array<any>>([]);
	const [residences, setResidences] = useState<Array<any>>([]);

	useEffect(() => {
		//Remove name, phone and email filters
		const temp = {
			...volunteerTableFilters,
			name: undefined,
			phone: undefined,
			email: undefined,
			objectId: undefined,
		};

		getData(temp);
		getStates();
		getHobbies();
		getProvince();
		getCountry();
		getLanguages();
		getCoorporates();
		getResidences();
	}, []);

	const getData = async (filter: any) => {
		setLoading(true);
		if (filter.objectId) {
			User.getById(filter.objectId)
				.then((result) => {
					setVolunteers([result.data]);
					setCount(1);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setVolunteers([]);
					setCount(0);
					setLoading(false);
				});
		} else {
			let data = {};
			let internalData = {};
			if (filter.hobbies) data = { ...data, hobbies: filter.hobbies };
			if (filter.languages)
				data = { ...data, languages: filter.languages };
			if (filter.lopdUrl)
				internalData = {
					...internalData,
					lopdUrl: filter.lopdUrl === "Firmada" ? true : false,
				};
			User.get({
				...filter,
				hobbies: undefined,
				languages: undefined,
				lopdUrl: undefined,
				type: "volunteer",
				limit: filter.limit ? filter.limit : 20,
				descending: filter.name ? "name" : "createdAt",
				data: data,
				internalData: internalData,
			})
				.then((result) => {
					setVolunteers(result.data);
					setCount(result.pagination.count);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
				});
		}
	};

	const getStates = () => {
		const states = Constant.state();
		const temp = states.filter((item) => item.volunteer);
		const temp2 = temp.map((item) => ({
			...item,
			label: item.title,
		}));
		setStates(temp2);
	};

	const getHobbies = () => {
		const hobbies = Constant.hobbies();
		const temp = hobbies.map((item) => ({
			...item,
			label: item.title,
		}));
		setHobbies(temp);
	};

	const getProvince = () => {
		const provinces = Constant.cities();
		const temp = provinces.map((item) => ({
			...item,
			label: item.title,
		}));
		setProvinces(temp);
	};

	const getCountry = () => {
		const country = Constant.country();
		const temp = country.map((item) => ({
			...item,
			label: item.title,
		}));
		setCountries(temp);
	};

	const getLanguages = () => {
		const language = Constant.languages();
		const temp = language.map((item) => ({
			...item,
			label: item.title,
		}));
		setLanguages(temp);
	};

	const getResidences = async () => {
		Residence.get().then((result) => {
			const temp = result.data
				.map((it) => {
					return { label: it.name, id: it.objectId };
				})
				.sort(Constant.dynamicSort("label"));
			setResidences([
				{
					id: "home",
					label: "Sin residencia",
				},
				...temp,
			]);
		});
	};

	const getCoorporates = async () => {
		Corporate.get().then((result) => {
			const temp = result.data
				.map((it) => {
					return { label: it.name, id: it.objectId };
				})
				.sort(Constant.dynamicSort("label"));
			const newArray = [
				{ id: "aua", label: "Adopta Un Abuelo" },
				...temp,
			];
			setCoorporates(newArray);
		});
	};

	const onRowClick = (user: UserScheme, target?: string) => {
		if (target === "_blank")
			window.open("/voluntarios/perfil?id=" + user.objectId, "_blank");
		else navigate("/voluntarios/perfil?id=" + user.objectId);
	};

	const onFiltersChange = (result: any) => {
		setVolunteerTableFilters(result);
		let temp = result;
		if (result.coorporateId && result.coorporateId === "aua") {
			temp = {
				...temp,
				coorporateId: undefined,
				doesNotExist: ["Coorporate"],
			};
		}
		if (temp.residenceId && temp.residenceId === "home") {
			temp = {
				...temp,
				residenceId: undefined,
				doesNotExist: ["Residence"],
			};
		}
		getData(temp);
	};

	return (
		<SearchTable
			title={"Voluntarios"}
			filters={[
				{
					id: "state",
					placeholder: "Estado",
					options: states,
					selectedOptions: volunteerTableFilters.state,
					hideSearchBar: true,
					type: "multiple",
				},
				{
					id: "hobbies",
					placeholder: "Aficiones",
					options: hobbies,
					selectedOptions: volunteerTableFilters.hobbies,
					type: "multiple",
				},
				{
					id: "province",
					placeholder: "Provincia",
					type: "single",
					selectedOptions: volunteerTableFilters.province,
					options: provinces,
				},
				{
					id: "country",
					placeholder: "País",
					options: countries,
					selectedOptions: volunteerTableFilters.country,
					type: "multiple",
				},
				{
					id: "languages",
					placeholder: "Idioma",
					options: languages,
					selectedOptions: volunteerTableFilters.languages,
					type: "multiple",
				},
				{
					id: "coorporateId",
					placeholder: "Empresa",
					type: "single",
					selectedOptions: volunteerTableFilters.coorporateId,
					options: coorporates,
				},
				{
					id: "residenceId",
					placeholder: "Residencia",
					type: "single",
					options: residences,
					selectedOptions: volunteerTableFilters.residenceId,
				},
				{
					id: "lopdUrl",
					placeholder: "LOPD",
					type: "single",
					options: [
						{
							id: "Firmada",
							label: "Firmada",
						},
						{
							id: "No firmada",
							label: "No firmada",
						},
					],
					selectedOptions: volunteerTableFilters.lopdUrl,
				},
			]}
			columns={[
				{
					id: "name",
					title: "Nombre",
				},
				{
					id: "phone",
					title: "Teléfono",
				},
				{
					id: "coorporate",
					title: "Origen",
				},
				{
					id: "city",
					title: "Ciudad",
				},
				{
					id: "state",
					title: "Estado",
				},
			]}
			switchOptions={[
				{
					id: "list",
					icon: <List />,
				},
				{
					id: "map",
					icon: <MapPin />,
				},
			]}
			rows={volunteers}
			count={count}
			page={volunteerTableFilters.page}
			loading={loading}
			onRowClick={onRowClick}
			onFiltersChange={onFiltersChange}
		/>
	);
};
export default Table;
export interface Props {}
