import React, { useEffect, useState } from "react";
import {
	Residence,
	Constant,
	ResidenceGroup,
	ResidenceScheme,
	ErrorScheme,
	User,
	UserScheme,
} from "client-v2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import { bindActionCreators } from "redux";

import { List, MapPin, BarChart3 } from "lucide-react";
import SearchTable from "../../../components/Table/SearchTable";
import { Button } from "@adoptaunabuelo/react-components";

const Table = (props: Props) => {
	const dispatch = useDispatch();
	const { setResidenceTableFilters } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const residenceTableFilters = useSelector(
		(state: State) => state.residenceTableFilters
	);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [residences, setResidences] = useState<Array<ResidenceScheme>>([]);
	const [count, setCount] = useState(0);
	const [states, setStates] = useState<Array<any>>([]);
	const [programs, setPrograms] = useState<Array<any>>([]);
	const [provinces, setProvinces] = useState<Array<any>>([]);
	const [residenceGroup, setResidenceGroup] = useState<Array<any>>([]);
	const [adminResidences, setAdminResidences] = useState<Array<any>>([]);
	const [countEmpty, setCountEmpty] = useState(0);
	const currentUser: UserScheme = useSelector(
		(state: State) => state.currentUser
	);

	useEffect(() => {
		getData(residenceTableFilters);
		getAdminResidences();
		getResidenceGroup();
		getStates();
		getPrograms();
		getProvince();
	}, []);

	const getData = async (filter: any) => {
		setLoading(true);
		if (filter.objectId) {
			Residence.getById(filter.objectId)
				.then((result) => {
					setResidences([result.data]);
					setCount(1);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setResidences([]);
					setCount(0);
					setLoading(false);
				});
		} else {
			Residence.get({
				...filter,
				adminId: filter.adminId,
				ascending: "name",
				exists: undefined,
			})
				.then((result) => {
					setCountEmpty(
						result.nVolunteersEmpty ? result.nVolunteersEmpty : 0
					);
					setResidences(result.data);
					setCount(result.pagination.count);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
				});
		}
	};

	const getStates = () => {
		setStates([
			{
				id: "active",
				label: "Activa",
			},
			{
				id: "completed",
				label: "Completa",
			},
			{
				id: "inactive",
				label: "Inactiva",
			},
		]);
	};

	const getPrograms = () => {
		setPrograms([
			{
				id: "offline",
				label: "Visitas",
			},
			{
				id: "call",
				label: "Llamadas",
			},
		]);
	};

	const getProvince = () => {
		const provinces = Constant.cities();
		const temp = provinces.map((item) => ({
			...item,
			label: item.title,
		}));
		setProvinces(temp);
	};

	const getResidenceGroup = async () => {
		ResidenceGroup.get({
			ascending: "name",
		}).then((result) => {
			const temp = result.data.map((item) => ({
				id: item.objectId,
				label: item.name,
			}));
			setResidenceGroup(temp);
		});
	};

	const getAdminResidences = async () => {
		User.get({
			type: "admin",
			data: {
				department: ["support"],
			},
			active: true,
		}).then((result) => {
			const temp = result.data.map((item) => ({
				...item,
				id: item.objectId,
				label: item.name + " " + item.surname,
			}));
			setAdminResidences([...temp]);
		});
	};

	const onRowClick = (residence: ResidenceScheme, target?: string) => {
		if (target === "_blank") {
			window.open(
				"/residencias/perfil?id=" + residence.objectId,
				"_blank"
			);
		} else {
			navigate("/residencias/perfil?id=" + residence.objectId, {
				state: { adminResidences },
			});
		}
	};

	const onFiltersChange = (result: any) => {
		getData(result);
		setResidenceTableFilters(result);
	};

	const renderButtonView = () => (
		<Button
			style={{ height: 36, marginLeft: 12 }}
			onClick={() => navigate("/residencias/registro")}
		>
			Añadir
		</Button>
	);

	return (
		<SearchTable
			title={"Residencias"}
			filters={[
				{
					id: "state",
					placeholder: "Estado",
					type: "multiple",
					options: states,
					selectedOptions: residenceTableFilters.state,
					hideSearchBar: true,
				},
				{
					id: "type",
					placeholder: "Programas",
					options: programs,
					selectedOptions: residenceTableFilters.type,
					type: "multiple",
				},
				{
					id: "province",
					placeholder: "Provincia",
					type: "single",
					options: provinces,
					selectedOptions: residenceTableFilters.province,
				},
				{
					id: "residenceGroupId",
					placeholder: "Grupo",
					type: "single",
					options: residenceGroup,
					selectedOptions: residenceTableFilters.residenceGroupId,
				},
				{
					id: "adminId",
					placeholder: "Asignado a",
					type: "single",
					options: adminResidences,
					selectedOptions: residenceTableFilters.adminId,
					hideSearchBar: true,
				},
			]}
			columns={
				residenceTableFilters.showData
					? [
							{
								id: "name",
								title: "Nombre",
							},
							{
								id: "data.nVolunteersEmpty",
								title: "Voluntarios sin match",
							},
							{
								id: "data.nGrandpasEmpty",
								title: "Abuelos sin match",
								minValue: 0.5,
								midValue: 0.25,
								compare: "nGrandpas",
							},
							{
								id: "data.nGrandpas",
								title: "Abuelos",
							},
							{
								id: "residenceState",
								title: "Estado",
							},
							{
								id: "address",
								title: "Dirección",
							},
					  ]
					: [
							{
								id: "name",
								title: "Nombre",
							},
							{
								id: "type",
								title: "Programas",
							},
							{
								id: "address",
								title: "Dirección",
							},
							{
								id: "residenceState",
								title: "Estado",
							},
					  ]
			}
			switchOptions={[
				{
					id: "list",
					icon: <List />,
				},
				{
					id: "data",
					icon: <BarChart3 />,
				},
				{
					id: "map",
					icon: <MapPin />,
				},
			]}
			page={residenceTableFilters.page}
			rows={residences}
			count={count}
			secondaryCount={countEmpty}
			secondaryTitle={"Voluntarios sin match"}
			loading={loading}
			ButtonView={renderButtonView}
			onRowClick={onRowClick}
			onFiltersChange={onFiltersChange}
		/>
	);
};
export default Table;
export interface Props {}
