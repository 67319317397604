import React, { useState, useEffect } from "react";
import { Constant, UserScheme, User, Activity, ErrorScheme } from "client-v2";
import styled from "styled-components";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { State, actionsCreators } from "../../../redux";

import Card from "../../../components/Containers/Card";
import {
	Text,
	Button,
	ColorV2,
	Color,
	Menu,
	Modal,
} from "@adoptaunabuelo/react-components";
import {
	Phone,
	Globe,
	Edit2,
	MapPin,
	Mail,
	User as UserIcon,
	Fingerprint,
	FolderLock,
	FileDown,
	TriangleAlert,
	Ellipsis,
	Trash,
	MapPinCheckInside,
	CircleCheck,
} from "lucide-react";
import InfomationModal from "../../../components/Modals/InfomationModal";
import InfoCell from "../../../components/Cell/InfoCell";
import { bindActionCreators } from 'redux';

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 13px;
	align-items: center;
`;

const MissingPhoneContainer = styled.div`
	display: flex;
	flex-direction: row;
	color: ${ColorV2.border.red};
	align-items: center;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SectionInfo = (props: Props) => {
	const grandpa = props.user.type === "grandpa" ? true : false;
	const [showInfomationModal, setShowInfomationModal] = useState(false);
	const [exportData, setExportData] = useState<
		{ Admin: UserScheme; date: string; exportId: string } | undefined
	>(undefined);
	const [loading, setLoading] = useState(false);
	const currentUser: UserScheme = useSelector(
		(state: State) => state.currentUser
	);
	const dispatch = useDispatch();

	const { setProfileFunction } = bindActionCreators(actionsCreators, dispatch);

	const [showVerifyAddressModal, setShowVerifyAddressModal] = useState(false);

	useEffect(() => {
		getExportData();
	}, []);

	const getExportData = async () => {
		const exportArray = props.user.internalData?.export;
		if (exportArray) {
			const temp = await Promise.all(
				exportArray.map(async (item) => {
					const admin = await User.getById(item.adminId);
					const date = new Date(item.date.iso);
					return {
						...item,
						date: moment(date).format("DD/MM/YYYY"),
						Admin: admin.data,
					};
				})
			);
			temp.reduce((a, b) => (a.date > b.date ? a : b));
			setExportData(temp[0]);
		}
	};

	const userOptionClick = () => {
		setLoading(true);

		if (!props.user.internalData?.verifiedAddress) {
			User.set(props.user.objectId, {
				internalData: {
					verifiedAddress: true,
				},
			})
				.then((result) => {
					props.onUserChanged(result.data);
					setLoading(false);
					setShowVerifyAddressModal(false);
					Activity.create({
						type: "verifyAddress",
						adminId: currentUser.objectId,
						toId: props.user.objectId,
						data: {
							address: props.user.address,
						},
					})
						.then(() => {
							setProfileFunction({ launch: true, function: "activity" });
							setLoading(false);
						})
						.catch((e: ErrorScheme) => {
							setLoading(false);
						});
				})
				.catch((error) => {});
		} else if (props.user.internalData?.verifiedAddress) {
			User.set(props.user.objectId, {
				internalData: {
					verifiedAddress: false,
				},
			})
				.then((result) => {
					props.onUserChanged(result.data);
					setLoading(false);
					setShowVerifyAddressModal(false);
					Activity.create({
						type: "unverifyAddress",
						adminId: currentUser.objectId,
						toId: props.user.objectId,
						data: {
							address: props.user.address,
						},
					})
						.then(() => {
							setProfileFunction({ launch: true, function: "activity" });
							setLoading(false);
						})
						.catch((e: ErrorScheme) => {
							setLoading(false);
						});
				})
				.catch((error) => {});
		}
	};

	return (
		<>
			<Modal
				isVisible={showVerifyAddressModal}
				shouldCloseOnOverlayClick={true}
				title={
					props.user.internalData?.verifiedAddress
						? "¿Quieres quitar la verificación a esta dirección?"
						: "¿Quieres verificar la dirección?"
				}
				onClose={() => setShowVerifyAddressModal(false)}
				buttonProps={{
					children: props.user.internalData?.verifiedAddress
						? "Quitar verificación"
						: "Verificar Dirección",
					onClick: userOptionClick,
					loading: loading,
				}}
			/>
			<InfomationModal
				isVisible={showInfomationModal}
				user={props.user}
				onClose={() => setShowInfomationModal(false)}
				onSave={(user) => {
					setShowInfomationModal(false);
					props.onUserChanged(user);
				}}
			/>

			<Card style={{ marginBottom: 16 }}>
				<TitleHeader>
					<Text type="p" weight={"semibold"}>
						Información
					</Text>
					<Button
						style={{ position: "absolute", right: 16, top: 12 }}
						design="image"
						onClick={() => setShowInfomationModal(true)}
						icon={<Edit2 height={20} width={20} />}
					/>
				</TitleHeader>
				{props.user.phone ? (
					<InfoCell
						title={"Teléfono"}
						subtitle={props.user.phone}
						icon={Phone}
					/>
				) : (
					<InfoCell
						title={"Teléfono"}
						subtitle={
							<MissingPhoneContainer>
								<Text
									type="p"
									style={{
										color: ColorV2.border.red,
									}}
								>
									Falta el número de teléfono
								</Text>
								<TriangleAlert
									color="red"
									style={{ marginLeft: 5 }}
								/>
							</MissingPhoneContainer>
						}
						icon={() => <Phone color="red" />}
					/>
				)}

				<InfoCell
					title={"Email"}
					subtitle={props.user.email ? props.user.email : "-"}
					icon={Mail}
				/>
				<InfoCell
					title={"Género"}
					subtitle={
						props.user.gender
							? props.user.gender === "male"
								? "Hombre"
								: props.user.gender === "female"
								? "Mujer"
								: "Otro"
							: "-"
					}
					icon={UserIcon}
				/>
				<InfoCell
					title="Idiomas"
					subtitle={
						props.user.data &&
						props.user.data.languages &&
						props.user.data.languages.length > 0
							? props.user.data.languages.map((language: any) => {
									const temp = Constant.languages().filter(
										(temp: any) => temp.id === language
									);
									return temp && temp.length > 0
										? temp[0].title + ", "
										: "-, ";
							  })
							: "-"
					}
					icon={Globe}
					empty={
						grandpa
							? props.user.data &&
							  props.user.data.languages &&
							  props.user.data.languages.length > 0
								? false
								: true
							: false
					}
				/>
				<Row>
					<InfoCell
						title={props.user.internalData?.verifiedAddress ? "Dirección verificada" : "Dirección"}
						subtitle={props.user.address ? props.user.address : "-"}
						customIcon={
							props.user.internalData?.verifiedAddress ? (
								<MapPinCheckInside
									size={20}
									color={ColorV2.border.primary}
								/>
							) : (
								<MapPin size={20} color={Color.text.high} />
							)
						}
						empty={grandpa && !props.user.address ? true : false}
					/>
					{props.user.address && (
						<Menu
							id="user-options"
							icon={
								<Ellipsis
									height={20}
									width={20}
									color={ColorV2.border.neutralHigh}
								/>
							}
							options={[
								props.user.internalData?.verifiedAddress
									? {
											id: "unverify",
											label: "Quitar verificación",
											labelColor: ColorV2.text.red,
											icon: (
												<Trash
													height={20}
													width={20}
													color={ColorV2.border.red}
												/>
											),
									  }
									: {
											id: "verify",
											label: "Verificar dirección",
											icon: (
												<CircleCheck
													height={20}
													width={20}
													color={
														ColorV2.border
															.neutralHard
													}
												/>
											),
									  },
							]}
							position={"bottom-left"}
							onClick={() => setShowVerifyAddressModal(true)}
						/>
					)}
				</Row>
				<InfoCell
					title={"DNI o NIE"}
					subtitle={props.user.idCard ? props.user.idCard : "-"}
					icon={Fingerprint}
				/>
				{exportData && (
					<InfoCell
						title={"Última exportación"}
						subtitle={
							exportData
								? `${exportData.Admin.name} ${exportData.Admin.surname} (${exportData.date})`
								: "-"
						}
						icon={FileDown}
					/>
				)}
				<InfoCell
					title={"Documento LOPD"}
					subtitle={
						<a
							href={props.user.internalData?.lopdUrl}
							target={"_blank"}
						>
							{props.user.internalData?.lopdUrl
								? "Ver documento"
								: "-"}
						</a>
					}
					icon={FolderLock}
				/>
			</Card>
		</>
	);
};

export default SectionInfo;
export interface Props {
	user: UserScheme;
	onUserChanged: (user: UserScheme) => void;
}
