import React, { useState } from "react";
import styled from "styled-components";
import { ChallengeScheme } from "client-v2";
import moment from "moment";

import Card from "../../../components/Containers/Card";
import InfoModal from "./InfoModal";
import { Pen } from "lucide-react";
import {
	Color,
	Text,
	Button,
	ProgressBar,
	Label,
} from "@adoptaunabuelo/react-components";

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const ImageView = styled.div`
	position: relative;
	height: 200px;
	margin: -21px -16px 18px;
	border-radius: 12px 12px 0px 0px;
	overflow: hidden;
`;
const Image = styled.img`
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
	background-color: ${Color.background.soft};
`;

const SectionMain = (props: Props) => {
	const completed = props.challenge.amount >= props.challenge.goal;
	const [showModal, setShowModal] = useState(false);

	return (
		<Card style={{ marginBottom: 16 }}>
			<InfoModal
				isVisible={showModal}
				challenge={props.challenge}
				onClose={() => setShowModal(false)}
				onSave={(challenge: ChallengeScheme) => {
					setShowModal(false);
					props.setChallenge(challenge);
				}}
			/>
			<ImageView>
				<Image src={props.challenge.image?.url} />
				<Button
					style={{
						position: "absolute",
						top: 8,
						right: 8,
						backgroundColor: "white",
						borderRadius: 40,
					}}
					design="image"
					icon={<Pen height={20} width={20} />}
					onClick={() => setShowModal(true)}
				/>
				{!props.challenge.image && (
					<Label
						style={{
							position: "absolute",
							top: 58,
							left: 24,
							backgroundColor: Color.status.color.warning,
						}}
						text="Falta añadir imagen"
					/>
				)}
				<Label
					style={{
						position: "absolute",
						top: 24,
						left: 24,
						backgroundColor:
							props.challenge.type === "dream"
								? "#EBF9FF"
								: "#EBECFF",
					}}
					text={
						props.challenge.type === "dream"
							? "Sueño"
							: props.challenge.type === "signature"
							? "Firmas"
							: "Cumpleaños"
					}
					color={
						props.challenge.type === "dream" ? "#2D55B5" : "#5963F6"
					}
				/>
			</ImageView>
			<Text type="h5" weight={"semibold"}>
				{props.challenge.title}
			</Text>
            <Row style={{alignItems: 'flex-end', marginTop: 24}}>
                <Text type='p' style={{color: completed ? Color.status.color.success : Color.text.deepBlue}}>
                    <span style={{fontWeight: 600}}>{props.challenge.amount}{props.challenge.type === 'signature' ? '' : '€'}</span> de {props.challenge.goal}{props.challenge.type === 'signature' ? ' firmas' : '€'}
                </Text>
            </Row>
            <Row style={{marginTop: 8}}>
                <ProgressBar
                    style={{height: 8, marginBottom: 12}}
                    progress={[{
                        value: props.challenge.amount/props.challenge.goal*100,
                        color: completed ? Color.status.color.success : Color.background.deepBlue
                    }]}
                    animationTime={0.3}
                    animationDelay={0.5}
                />
            </Row>
            <Row style={{justifyContent: 'space-between'}}>
                {props.challenge.type !== 'signature' &&
                    <Text type='p2' style={{flexDirection: 'row', display: 'flex'}}>
                        <Text type='p2' weight='semibold' style={{marginRight: 5}}>
                            {props.challenge.supporters}
                        </Text>
                        donantes
                    </Text>
                }
                {!completed &&
                    <Text type='p2' style={{flexDirection: 'row', display: 'flex'}}>
                        <Text type='p2' weight='semibold' style={{marginRight: 5}}>
                            {moment(props.challenge.endDate.iso).diff(moment(), 'days')}
                        </Text>
                        días restantes
                    </Text>
                }
            </Row>
            <Text type='p' style={{marginTop: 24}}>
				{props.challenge.history}
			</Text>
		</Card>
	);
};

export default SectionMain;
export interface Props {
	challenge: ChallengeScheme;
	setChallenge: (challenge: ChallengeScheme) => void;
}
