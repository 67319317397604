import React, { ReactNode } from "react";
import styled from "styled-components";
import { Text, Color } from "@adoptaunabuelo/react-components";

const Cell = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 0px;
`;

const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const InfoCell = (props: Props) => {
	return (
		<Cell style={props.style}>
			{props.customIcon ? (
				props.customIcon
			) : (
				<props.icon height={20} width={20} color={Color.text.high} />
			)}

			<Column style={{ marginLeft: 12 }}>
				<Text
					type="c1"
					style={{
						width: "fit-content",
						color: Color.text.high,
						backgroundColor: props.empty
							? Color.status.color.warning
							: "transparent",
					}}
				>
					{props.title}
				</Text>
				<Text type="p2">{props.subtitle}</Text>
			</Column>
		</Cell>
	);
};

export default InfoCell;

export interface Props {
	style?: React.CSSProperties;
	title: string;
	subtitle: any;
	icon?: any;
	customIcon?: ReactNode;
	empty?: boolean;
}
