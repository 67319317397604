import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
	User,
	Product,
	Subscription,
	Payment,
	AUA,
	UserScheme,
	PaymentMethodScheme,
	SubscriptionScheme,
	ProductScheme,
	PaymentMethod,
	ErrorScheme,
} from "client-v2";
import Stripe from "stripe";
import moment from "moment";

import {
	Modal,
	Color,
	Text,
	Button,
	Input,
	Checkbox,
	Tabs,
	Payout,
	Dropdown,
	ColorV2,
	Form,
} from "@adoptaunabuelo/react-components";
import PaycardIcon from "../../assets/img/Paycard";
import { Plus, Landmark } from "lucide-react";
import ReliefModal from "./ReliefModal";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 0px 24px;
`;
const FormContainer = styled.div`
	margin: 4px 0px 16px;
`;
const PriceContainer = styled.div`
	display: flex;
	flex-direction: row;
`;
const PaymentMethodView = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 36px;
`;
const PaycardView = styled.div`
	display: flex;
	align-items: center;
`;

const SubscriptionModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>("");

	const [selectedPrice, setSelectedPrice] = useState<number>(
		props.product ? props.product.price : 15
	);
	const [selectedOption, setSelectedOption] = useState<string>("card");
	const [selectedTab, setSelectedTab] = useState<string | undefined>(
		props.product ? props.product.charge : undefined
	);
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState<
		string | undefined
	>(undefined);
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		undefined
	);
	const [selectedIdCard, setSelectedIdCard] = useState(props.user.idCard);
	const [newPaymentMethod, setNewPaymentMethod] = useState<{
		name?: string;
		IBAN?: string;
	}>({
		name: undefined,
		IBAN: undefined,
	});

	const [showModal, setShowModal] = useState(false);
	const [showDateModal, setShowDateModal] = useState(false);
	const [paymentIntent, setPaymentIntent] = useState<
		Stripe.PaymentIntent | undefined
	>(undefined);
	const [confirmUrl, setConfirmUrl] = useState<string | undefined | null>(
		undefined
	);
	const [showPayout, setShowPayout] = useState(false);
	const [timeOption, setTimeOption] = useState<string | undefined>(undefined);
	const [modalError, setModalError] = useState<string | undefined>(undefined);
	const [showSuccess, setShowSuccess] = useState(false);
	const [reliefAmount, setReliefAmount] = useState("0");
	const [showReliefModal, setShowReliefModal] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodScheme[]>(
		[]
	);
	const [form, setForm] = useState<any>({
		address: "",
		sortAddress: "",
		route: "",
		routeNumber: "",
		routeInfo: "",
		city: "",
		province: "",
		zipCode: "",
		country: "",
		location: {
			lat: 0,
			lng: 0,
		},
		timeZone: "",
	});
	const [paymentOption, setPaymentOption] = useState<"sepa_debit" | "card">(
		"sepa_debit"
	);

	const payout = useRef<any>(null);
	const modal = useRef<any>(null);

	window.addEventListener(
		"message",
		function (ev) {
			if (ev.data === "3DS-authentication-complete" && showModal) {
				modal.current.close();
			}
		},
		false
	);

	useEffect(() => {
		if (props.paymentMethods) {
			setPaymentMethods(props.paymentMethods);
		} else {
			setPaymentMethods([]);
		}
	}, [props.paymentMethods]);

	useEffect(() => {
		if (props.product) {
			setSelectedTab(props.product.charge);
			setSelectedPrice(props.product.price);
			getReliefAmount(props.product.price, props.product.charge);
		} else {
			setSelectedTab("monthly");
			getReliefAmount(selectedPrice, "monthly");
		}
	}, [props.product]);

	useEffect(() => {
		if (selectedTab) {
			//Change payment methods
			if (selectedTab === "one") {
				setSelectedOption("card");
				const temp = props.paymentMethods?.filter(
					(i) => i.type === "paycard"
				);
				setPaymentMethods(temp ? temp : []);
			} else {
				const temp = props.paymentMethods;
				setPaymentMethods(temp ? temp : []);
			}
			const tempPrice = selectedTab === "monthly" ? 15 : 150;
			setSelectedPrice(tempPrice);
			getReliefAmount(tempPrice, selectedTab);
		}
	}, [selectedTab]);

	useEffect(() => {
		if (props.isVisible) {
			if (props.selectedTab) {
				setSelectedTab(props.selectedTab);
			}
		} else {
			setShowSuccess(false);
		}
	}, [props.isVisible]);

	const onTabUpPress = (item: any) => {
		setSelectedTab(item.id);
	};

	const onDateSave = () => {
		if (selectedDate && selectedDate > new Date()) {
			setShowDateModal(false);
		} else {
			setSelectedDate(undefined);
			setModalError("La fecha debe ser mayor a la fecha actual");
		}
	};

	const saveIdCard = () => {
		setLoading(true);
		User.set(props.user.objectId, {
			idCard: selectedIdCard,
			address: form.address,
			shortAddress: form.sortAddress,
			city: form.city,
			country: form.country,
			timeZone: form.timeZone,
			zipCode: form.zipCode,
			location: {
				lat: form.location.lat,
				lng: form.location.lng,
			},
			route: form.route,
			routeNumber: form.routeNumber,
			routeInfo: form.routeInfo,
			province: form.province,
		})
			.then(() => {
				setLoading(false);
				if (showSuccess) props?.onSave();
			})
			.catch((e: ErrorScheme) => {
				setLoading(false);
				setError(e.message);
			});
	};

	const activateSubscription = () => {
		if (selectedTab === "one") {
			makeOneOffPayment(selectedPrice);
		} else {
			setLoading(true);
			Product.get({
				active: true,
				type: "subscription",
				charge: selectedTab,
				price: selectedPrice,
			})
				.then(async (result) => {
					if (result.data && result.data.length > 0) {
						if (props.subscription && props.product) {
							//Update subscription
							updateSubscription(result.data[0]);
						} else {
							//Init subscription
							if (selectedOption === "sms")
								sendUrlBy("sms", result.data[0]);
							else if (selectedOption === "whatsapp")
								sendUrlBy("whatsapp", result.data[0]);
							else if (selectedOption === "email")
								sendUrlBy("email", result.data[0]);
							else {
								//Save payment method if needed
								const newStripePaymentMethod =
									await payout.current?.getPaymentMethod();
								if (
									newPaymentMethod.IBAN &&
									newPaymentMethod.name
								) {
									PaymentMethod.initSetupIntent({
										userId: props.user.objectId,
										name: newPaymentMethod.name,
										IBAN: newPaymentMethod.IBAN,
									})
										.then((result2) => {
											const paymentMethod =
												result2.data as PaymentMethodScheme;
											initSubscription(
												result.data[0],
												paymentMethod.objectId
											);
										})
										.catch((e) => {
											setLoading(false);
											setError(e);
										});
								} else if (newStripePaymentMethod) {
									initSubscription(
										result.data[0],
										newStripePaymentMethod.id
									);
								} else if (currentPaymentMethod) {
									initSubscription(
										result.data[0],
										currentPaymentMethod
									);
								} else {
									setLoading(false);
									setError(
										"Debes añadir un método de pago antes de continuar"
									);
								}
							}
						}
					} else {
						setLoading(false);
						setError(error);
					}
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setError(error.message);
				});
		}
	};

	const sendUrlBy = async (
		by: "sms" | "whatsapp" | "email",
		product: ProductScheme
	) => {
		setLoading(true);
		Subscription.sendUrlBy({
			by: by,
			userId: props.user.objectId,
			productId: product.objectId,
			adminId: props.currentUser.objectId,
		});
		props.onClose();
		setLoading(false);
	};

	const initSubscription = async (
		product: ProductScheme,
		paymentMethod: string
	) => {
		try {
			setLoading(true);
			const initDate = selectedDate
				? moment(selectedDate)
				: timeOption === "1"
				? moment().add(1, "M").startOf("month")
				: timeOption === "12"
				? moment().add(1, "M").startOf("month").add(12, "days")
				: undefined;
			Subscription.create({
				userId: props.user.objectId,
				productId: product.objectId,
				adminId: props.currentUser.objectId,
				paymentMethod: paymentMethod,
				currency: "eur",
				initDate: initDate ? initDate.toDate() : undefined,
			})
				.then(async (result) => {
					setShowSuccess(true);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setError(error.message);
				});
		} catch (e) {
			setLoading(false);
			setError("Algo ha salido mal...");
		}
	};

	const makeOneOffPayment = async (amount: number) => {
		try {
			setLoading(true);
			const newPaymentMethod = await payout.current?.getPaymentMethod();
			const paymentMethod = newPaymentMethod
				? newPaymentMethod.id
				: currentPaymentMethod;
			if (paymentMethod) {
				Payment.initPaymentIntent({
					userId: props.user.objectId,
					amount: amount,
					currency: "eur",
					redirectUrl: "https://adoptaunabuelo.org/card-success",
					paymentMethod: paymentMethod,
				})
					.then((result) => {
						//Check if 3d secure needed
						if (
							result.data.status === "processing" ||
							result.data.status === "succeeded"
						) {
							setLoading(false);
							setShowSuccess(true);
						} else {
							setPaymentIntent(result.data);
							setConfirmUrl(
								result.data.next_action &&
									result.data.next_action.redirect_to_url &&
									result.data.next_action.redirect_to_url.url
							);
							setShowModal(true);
						}
					})
					.catch((error: ErrorScheme) => {
						setLoading(false);
						setError(error.message);
					});
			} else {
				setLoading(false);
				setError("Debes añadir un método de pago antes de continuar");
			}
		} catch (e) {
			setLoading(false);
			setError("Algo ha salido mal...");
		}
	};

	const updateSubscription = async (product: ProductScheme) => {
		try {
			if (props.product?.price !== product.price && props.subscription) {
				setLoading(true);
				Subscription.set(props.subscription.objectId, {
					productId: product.objectId,
					adminId: props.currentUser.objectId,
				})
					.then((result) => {
						setLoading(false);
						setShowSuccess(true);
					})
					.catch((error: ErrorScheme) => {
						setLoading(false);
						setError(error.message);
					});
			} else {
				setLoading(false);
				props.onClose();
			}
		} catch (e) {
			setLoading(false);
			setError("Algo ha salido mal...");
		}
	};

	const onSelectionChange = (options: Array<any>) => {
		setSelectedDate(undefined);
		setSelectedOption(options[0].id);
		if (options[0].id === "card") {
			if (paymentMethods && paymentMethods.length > 0)
				setShowPayout(false);
			else setShowPayout(true);
		} else setShowPayout(false);
	};

	const onCurrentPaymentMethodChange = (op: Array<any>) => {
		setCurrentPaymentMethod(op[0].id);
	};

	const onConfirmModalClose = async () => {
		if (paymentIntent && paymentIntent.id) {
			setShowModal(false);
			Payment.finishPaymentIntent(paymentIntent.id)
				.then((result) => {
					if (result.data.status === "succeeded") {
						setLoading(false);
						setShowSuccess(true);
					} else {
						setError("Algo ha salido mal...");
					}
				})
				.catch((error: ErrorScheme) => {
					setError(error.message);
				});
		} else {
			setError("Algo ha salido mal...");
		}
	};

	const getReliefAmount = (price: number, charge: string) => {
		const totalAmount = charge === "monthly" ? price * 12 : price;
		const reliefSection1 = totalAmount > 250 ? 250 : totalAmount;
		const reliefSection2 = totalAmount > 250 ? totalAmount - 250 : 0;
		const relief = reliefSection1 * 0.8 + reliefSection2 * 0.4;
		setReliefAmount(relief.toFixed(2));
	};

	const onLocationChangeForm = (result: any) => {
		setForm(result.data);
	};

	return (
		<Modal
			isVisible={props.isVisible}
			onClose={() => {
				setError("");
				props.onClose();
			}}
			title={showSuccess ? "✅ Proceso completado" : "Donación"}
			error={error}
			buttonProps={{
				onClick: showSuccess ? saveIdCard : activateSubscription,
				loading: loading,
				disabled: !selectedOption,
				children: showSuccess
					? "Guardar"
					: selectedOption !== "card"
					? "Enviar mensaje"
					: (props.subscription ? "Actualizar a " : "Donar ") +
					  selectedPrice +
					  "€" +
					  (selectedTab === "annually"
							? "/año"
							: selectedTab === "monthly"
							? "/mes"
							: "") +
					  (selectedDate
							? " el " + moment(selectedDate).format("DD/MM/YYYY")
							: ""),
			}}
			Bottom={
				!showSuccess &&
				!props.subscription &&
				!props.product &&
				selectedOption === "card" &&
				selectedTab !== "one" ? (
					<Dropdown
						id={"time-dropdown"}
						style={{ marginRight: 8 }}
						placeholder={"Fecha de cobro"}
						menuPosition={"top"}
						type={"single"}
						options={[
							{
								id: "now",
								title: "Ahora mismo",
							},
							{
								id: "1",
								title: "El primer día del mes",
							},
							{
								id: "12",
								title: "El día 12 del mes",
							},
							{
								id: "other",
								title: "Otro día",
							},
						]}
						onChange={(op) => {
							setSelectedDate(undefined);
							setTimeOption(op[0].id);
							if (op[0].id === "other") setShowDateModal(true);
						}}
						selectedOptions={
							timeOption
								? [{ id: timeOption }]
								: [
										{
											id: "now",
											title: "Ahora mismo",
										},
								  ]
						}
					/>
				) : undefined
			}
		>
			<Modal
				type="web"
				ref={modal}
				isVisible={showModal}
				hideClose={false}
				url={confirmUrl ? confirmUrl : ""}
				style={{ width: 600, height: 400, padding: 0 }}
				onClose={onConfirmModalClose}
			/>
			<ReliefModal
				isVisible={showReliefModal}
				price={selectedPrice}
				charge={selectedTab ? selectedTab : "monthly"}
				onClose={() => setShowReliefModal(false)}
			/>
			<Modal
				isVisible={showDateModal}
				title="Seleccionar día"
				buttonProps={{
					children: "Guardar",
					onClick: onDateSave,
				}}
				error={modalError}
				onClose={() => setShowDateModal(false)}
			>
				<Input
					containerStyle={{ margin: "4px 0px 8px" }}
					type="date"
					design={"secondary"}
					placeholder="Fecha de inicio"
					hideCalendar={false}
					onChange={(e: any) => {
						setModalError(undefined);
						setSelectedDate(e);
					}}
				/>
			</Modal>
			{showSuccess ? (
				<Container>
					{!props.user.city && (
						<>
							<Text
								type="p"
								weight="regular"
								style={{ marginLeft: 8 }}
							>
								Dirección
							</Text>
							<FormContainer>
								<Form
									type="location"
									design="primary"
									googleAPIKey="AIzaSyDt-MpIS9a8SsQ-e1v6I6KrmnOLK4SA-f8"
									onSubmit={onLocationChangeForm}
								/>
							</FormContainer>
						</>
					)}
					<Text type="p" weight="regular" style={{ marginLeft: 8 }}>
						DNI / NIE
					</Text>
					<Input
						type="text"
						placeholder="DNI o NIE"
						defaultValue={selectedIdCard}
						onChange={(e: any) => setSelectedIdCard(e.target.value)}
					/>
				</Container>
			) : (
				<Container>
					<PriceContainer style={{ marginTop: 8 }}>
						{(!props.product || !props.subscription) && (
							<Tabs
								options={
									props.selectedTab
										? [
												{
													id: props.selectedTab,
													title: "Puntual",
												},
										  ]
										: [
												{
													id: "monthly",
													title: "Mensual",
												},
												{
													id: "annually",
													title: "Anual",
												},
												{
													id: "one",
													title: "Puntual",
												},
										  ]
								}
								selectedOption={
									selectedTab
										? { id: selectedTab, title: "Mensual" }
										: undefined
								}
								onChange={(op) => onTabUpPress(op)}
							/>
						)}
					</PriceContainer>
					<PriceContainer
						style={{
							marginTop: 24,
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Text
							type="h1"
							weight="medium"
							style={{
								position: "relative",
								fontFamily: "DM Mono",
								textAlign: "center",
								color: Color.text.primary,
							}}
						>
							{selectedPrice}
							<Text
								type="h4"
								weight="medium"
								style={{
									position: "absolute",
									top: 8,
									left: -26,
									color: Color.text.primarySoft,
								}}
							>
								€
							</Text>
						</Text>
						<Text
							type="p2"
							style={{ textAlign: "center", marginTop: 12 }}
						>
							{"Le devolverán "}
							<span
								style={{
									backgroundColor: ColorV2.surface.secondary,
									padding: "1px 6px",
									borderRadius: 3,
									fontWeight: 500,
								}}
							>
								{reliefAmount.split(".")[0]}
								<span style={{ fontSize: 12 }}>
									{"," + reliefAmount.split(".")[1]} €
								</span>
							</span>
							{" en la declaración de la Renta"}
						</Text>
						<Button
							design={"call-to-action"}
							style={{
								color: ColorV2.text.neutralHard,
								textDecoration: "underline",
								fontSize: 14,
								fontWeight: 400,
								marginTop: 5,
								marginBottom: 12,
							}}
							onClick={() => setShowReliefModal(true)}
						>
							Más información
						</Button>
						<Input
							style={{ paddingTop: 12 }}
							type="range"
							min={
								selectedTab === "annually"
									? 50
									: selectedTab === "monthly"
									? 3
									: 3
							}
							max={
								selectedTab === "annually"
									? 200
									: selectedTab === "monthly"
									? 30
									: 200
							}
							value={selectedPrice}
							unit={"€"}
							step={
								selectedTab === "annually"
									? 10
									: selectedTab === "monthly"
									? 1
									: 5
							}
							hideRange={true}
							onChange={(e: any) => {
								const price = parseInt(e.target.value);
								setSelectedPrice(price);
								if (selectedTab)
									getReliefAmount(price, selectedTab);
							}}
						/>
					</PriceContainer>
					{(!props.product || !props.subscription) && (
						<>
							<Checkbox
								style={{ marginTop: 32 }}
								type="single"
								options={
									selectedTab === "one"
										? [
												{
													id: "card",
													label: "Seleccionar método de pago",
												},
										  ]
										: [
												{
													id: "whatsapp",
													label: "Enviar Whatsapp para completar la donación",
												},
												{
													id: "sms",
													label: "Enviar SMS para completar la donación",
												},
												{
													id: "email",
													label: "Enviar email para completar la donación",
												},
												{
													id: "card",
													label: "Seleccionar método de pago",
												},
										  ]
								}
								selectedOptions={[{ id: selectedOption }]}
								onChange={onSelectionChange}
							/>
							{selectedOption === "card" && (
								<>
									{showPayout ? (
										<>
											<Tabs
												style={{ marginBottom: 12 }}
												options={[
													{
														id: "sepa_debit",
														title: "IBAN",
													},
													{
														id: "card",
														title: "Tarjeta",
													},
												]}
												onChange={(op: any) =>
													setPaymentOption(op.id)
												}
											/>
											{paymentOption === "sepa_debit" ? (
												<Input
													type="text"
													placeholder="IBAN"
													icon={
														<Landmark
															height={18}
															width={18}
															color={
																ColorV2.border
																	.neutralHigh
															}
														/>
													}
													onChange={(e) => {
														const ibanRegex =
															/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
														if (
															ibanRegex.test(
																e.target.value
															)
														) {
															setModalError(
																undefined
															);
															setNewPaymentMethod(
																{
																	...newPaymentMethod,
																	name:
																		props
																			.user
																			.name +
																		" " +
																		props
																			.user
																			.surname,
																	IBAN: e
																		.target
																		.value,
																}
															);
														} else {
															setNewPaymentMethod(
																{
																	...newPaymentMethod,
																	name: undefined,
																	IBAN: undefined,
																}
															);
															setError(
																"Añade un IBAN válido"
															);
														}
													}}
												/>
											) : (
												<Payout
													ref={payout}
													style={{
														width: "100%",
													}}
													stripeKey={AUA.getStripeKey()}
													paymentOption={
														paymentOption
													}
													userData={{
														email: props.user.email,
													}}
												/>
											)}
										</>
									) : (
										paymentMethods && (
											<PaymentMethodView>
												<Checkbox
													type="single"
													options={paymentMethods?.map(
														(item) => ({
															id: item.objectId,
															Element: (
																<PaycardView>
																	<PaycardIcon
																		brand={
																			item.brand
																		}
																		style={{
																			height: 28,
																			width: 28,
																			borderRadius: 6,
																			marginRight: 16,
																		}}
																	/>
																	<Text type="p">
																		{"**** **** **** " +
																			item.last4}
																	</Text>
																</PaycardView>
															),
														})
													)}
													onChange={
														onCurrentPaymentMethodChange
													}
												/>
												<Button
													style={{
														width: "fit-content",
														padding: 8,
														height: 32,
													}}
													icon={<Plus />}
													design={"text"}
													color={Color.text.primary}
													onClick={() =>
														setShowPayout(true)
													}
												>
													Añadir método de pago
												</Button>
											</PaymentMethodView>
										)
									)}
								</>
							)}
						</>
					)}
				</Container>
			)}
		</Modal>
	);
};
export default SubscriptionModal;
export interface Props {
	isVisible: boolean;
	user: UserScheme;
	currentUser: UserScheme;
	paymentMethods?: Array<PaymentMethodScheme>;
	subscription?: SubscriptionScheme;
	product?: ProductScheme;
	selectedTab?: "one" | "monthly" | "annually";
	onClose: () => void;
	onSave: () => void;
}
