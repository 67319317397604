import React, { useState } from "react";
import {
	Subscription,
	Grandpa,
	UserScheme,
	SubscriptionScheme,
	ErrorScheme,
} from "client-v2";
import Stripe from "stripe";

import { Modal, TagSelector } from "@adoptaunabuelo/react-components";

const PauseModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [resumeAt, setResumeAt] = useState<string | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	const title = props.subscription
		? props.subscription.state === "paused"
			? "¿Seguro que quieres reactivar esta donación?"
			: "¿Seguro que quieres pausar esta donación?"
		: props.user.state === "paused"
		? "¿Seguro que quieres reactivar este abuelo?"
		: "¿Seguro que quieres pausar este abuelo?";
	const subtitle = props.subscription
		? props.subscription.state === "paused"
			? "Al reactivar la donación se volverá a cobrar al voluntario periodicamente."
			: "Al pausar la donación se detendrán los cobros hasta la fecha seleccionada."
		: props.user.state === "paused"
		? "Podrá volver a recibir acompañamiento por parte de los voluntarios"
		: "Se procedera a poner en pausa el abuelo y no podra recibir llamadas";

	const onPausedConfirm = async () => {
		setLoading(true);
		if (props.subscription) {
			if (props.subscription.state === "paused") {
				Subscription.unpause({
					userId: props.user.objectId,
					adminId: props.currentUser.objectId,
				})
					.then((result) => {
						props.onSave(result.data);
					})
					.catch((error: ErrorScheme) => {
						setError(error.message);
					});
			} else {
				if (resumeAt) {
					const currentDate = new Date();
					currentDate.setMonth(
						currentDate.getMonth() + parseInt(resumeAt)
					);
					Subscription.pause({
						userId: props.user.objectId,
						resumeAt: currentDate,
						timePause: parseInt(resumeAt),
						motivation: "other",
						adminId: props.currentUser.objectId,
					})
						.then((result) => {
							props.onSave(result.data);
						})
						.catch((error: ErrorScheme) => {
							setError(error.message);
						});
				}
			}
		} else {
			if (props.user.state === "paused") {
				Grandpa.unpause(props.user.objectId)
					.then((result) => {
						props.onSave(result.data);
					})
					.catch((error: ErrorScheme) => {
						setError(error.message);
					});
			} else {
				if (resumeAt) {
					const currentDate = new Date();
					currentDate.setMonth(
						currentDate.getMonth() + parseInt(resumeAt)
					);
					Grandpa.pause(props.user.objectId, {
						resumeAt: currentDate.toString(),
					})
						.then((result) => {
							props.onSave(result.data);
						})
						.catch((error: ErrorScheme) => {
							setError(error.message);
						});
				}
			}
		}
		setLoading(false);
	};

	return (
		<Modal
			isVisible={props.isVisible}
			title={title}
			subtitle={subtitle}
			error={error}
			onClose={props.onClose}
			buttonProps={{
				loading: loading,
				onClick: onPausedConfirm,
				disabled:
					props.user.state === "paused" ||
					props.subscription?.state === "paused"
						? false
						: !resumeAt,
				children:
					props.user.state === "paused" ||
					props.subscription?.state === "paused"
						? "Sí, reactivar"
						: "Guardar",
			}}
		>
			{props.user.state !== "paused" &&
				props.subscription?.state !== "paused" && (
					<TagSelector
						style={{ marginBottom: 12 }}
						options={[
							{ title: "Pausar 1 mes", id: "1" },
							{ title: "Pausar 2 meses", id: "2" },
							{ title: "Pausar 3 meses", id: "3" },
						]}
						type={"single"}
						onChange={(value) =>
							setResumeAt(value[0] ? value[0].id : undefined)
						}
					/>
				)}
		</Modal>
	);
};
export default PauseModal;
export interface Props {
	isVisible: boolean;
	user: UserScheme;
	currentUser: UserScheme;
	subscription?: SubscriptionScheme;
	onClose: () => void;
	onSave: (
		object: UserScheme | SubscriptionScheme | Stripe.Subscription
	) => void;
}
