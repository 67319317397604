import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { Letter, ChallengeScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import { Text } from '@adoptaunabuelo/react-components';

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CongratulationsSection = (props: Props) =>{

	const [congratulationsCount, setCongratulationsCount] = useState(0);

	useEffect(() => {
		Letter.count({challengeId: props.challenge.objectId}).then((result) => {
			setCongratulationsCount(result.data);
		});
	},[]);

	return (
        <>
        <Card style={{marginBottom:16}}>
            <TitleHeader>
				<Text type='p' weight={"semibold"}>
					<>
						{congratulationsCount} Felicitaciones
					</>
				</Text>
			</TitleHeader>
        </Card>
        </>
	);
}

export default CongratulationsSection;
export interface Props{
	challenge: ChallengeScheme,
}